



































































import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ValidationProvider } from 'vee-validate';
import CsvImportGuide from '@/components/CsvImportGuide.vue';
import FileUpload from '@/components/FileUpload.vue';
import EditableMemberTable from '@/views/AdminCreateGroup/components/EditableMemberTable.vue';
import GroupCsvImportInfo from '@/views/AdminCreateGroup/components/GroupCsvImportInfo.vue';
import { MemberObject } from '@/utils/group.util';
import { ToastProgrammatic } from 'buefy';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { handleUserListUploading } from '@/utils/member-list-upload.util';
import { Action } from 'vuex-class';
import { MyjbiGroupUserAttributeSpec } from '@/jbi-shared/types/myjbi-group.types';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import { ValidateImportedMemberListPayload } from '@/store/modules/job-result-data/job-result-data.types';
import { isJobFailure } from '@/utils/bull-queue.util';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    ValidationProvider,
    CsvImportGuide,
    FileUpload,
    EditableMemberTable,
    GroupCsvImportInfo
  }
})
export default class GroupSettingsMembersSection extends mixins() {
  @Prop(Function) downloadSampleXlsx!: () => void;
  @Prop(Function) downloadSampleCsv!: () => void;
  @Prop(Array) membersData!: MemberObject[];
  @PropSync('membersData', Array) syncedMembersData!: MemberObject[];
  @PropSync('notify', Boolean) syncedNotify!: boolean;
  @Prop(Array) userAttributes!: MyjbiGroupUserAttributeSpec[];
  @Prop() membersFile!: File;
  @PropSync('membersFile') syncedMembersFile!: File;
  @Prop(String) requiredCsvColumns!: string;
  @Prop(Object) validationErrors!: any;
  @Prop(Boolean) showMemberLabel!: boolean;
  @Prop(Boolean) shouldDownloadExistingMemberInCsv!: boolean;
  @Prop(String) csvFileName!: string;
  @Prop() allowedEmailDomains!: string[];
  @Prop({ default: false }) isSettings!: boolean;
  @Prop() groupTypeName!: string;
  @Prop() groupId!: number;
  @Prop() groupExceptions!: ResourceExceptions;

  @Action('admin/uploadUserListFile')
  public uploadUserListFile!: (file: File) => Promise<any>;

  @Action('jobResultData/createImportedListValidationJob')
  public validateMemberList!: (
    payload: ValidateImportedMemberListPayload
  ) => Promise<any>;

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    return this.isSettings
      ? isUserAllowed(
          action,
          module,
          EntityTypes.GROUP,
          this.groupTypeName,
          this.groupId,
          this.groupExceptions,
          skipImplicitCheck
        )
      : true;
  }

  get errorNotification(): string {
    const erroredFieldCount = Object.entries(this.validationErrors)
      .filter(([key, arr]) => key.startsWith('obs_'))
      .map(([key, arr]) => (arr as string[]).length || 0)
      .flat()
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
    if (!erroredFieldCount) {
      return '';
    }
    return `${erroredFieldCount} missing field(s) detected.`;
  }

  get emailIndex() {
    return this.userAttributes
      ? this.userAttributes.findIndex(
          (attr) => attr.groupUserAttribute.slug?.toLowerCase() === 'email'
        )
      : 0;
  }

  @Watch('membersFile')
  @isTruthy
  public async onFilesAdded(file: File) {
    if (!this.membersFile) {
      return;
    }

    try {
      const gcsURL = await this.uploadUserListFile(file);

      const job = await this.validateMemberList({
        fileLink: gcsURL,
        emailIndex: this.emailIndex,
        userAttributesSpecs: this.userAttributes,
        emailDomains: this.allowedEmailDomains
      });

      const memberList = (await handleUserListUploading.call(
        this,
        job
      ));
      
      if (memberList) {
        // If the message returned from file upload handler is regarding job failure (i.e. file is invalid), emit the event to clear the 
        // selected file to allow the re-upload of a new file.
        if (isJobFailure(memberList)) {
          this.$emit('clear')

        } else {
          // Iterate through all the members data and if the attribute is required but
          // without a value, set the isValid for the field to false with error message.
          for (const [index, member] of memberList.entries()) {
            Object.keys(member).forEach((slug: string) => {
              const { isRequired, value } = memberList[index][slug];
              if (isRequired && (!value || value === '')) {
                memberList[index][slug].isValid = false;
                memberList[index][slug].errorMessage = 'This Field is Required';
              }
            });
          }

          this.syncedMembersData = memberList as MemberObject[];
        }
      }

      this.$emit('uploadComplete', true);
    } catch (error) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }
}
